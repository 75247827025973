<template>
	<div class="attendance">
		<el-form ref="form" :model="formData" :rules="formRules" label-width="120px" size="small" label-position="left">
		  <el-form-item label="展示标题" prop="padShowTitle">
		    <el-input v-model="formData.padShowTitle" placeholder="请输入标题"></el-input>
		  </el-form-item>
			<el-form-item label="展示二维码" prop="padShowImage">
				<div style="display: flex; justify-content: space-between;">
					<el-image :src="imageSrc" :preview-src-list="imageSrcList"></el-image>
					<el-upload class="upload-demo" drag :action="action" :headers="headers"
					:on-success="onSuccess" 
					:on-change="onChange"
					:before-upload="beforeAvatarUpload"
					:on-error="onError">
					  <i class="el-icon-upload"></i>
					  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					  <div class="el-upload__tip" slot="tip">只能上传jpg/png/gif/bmp文件，且不超过2M</div>
					</el-upload>
				</div>
			</el-form-item>
			<el-form-item label="引导关注内容" prop="padShowContent">
			  <el-input v-model="formData.padShowContent" type="textarea" :rows="6" placeholder="请输入详情"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit('form')">确 定</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import api from "../../../../api/setting.js";
	import { baseURL } from "../../../../utils/axios.js"
	export default {
		name: 'account',
		data() {
			return {
				formData:{ // 表单数据
					padShowTitle: '',
					padShowContent: '',
					padShowImage: ''
				},
				formRules: {
					padShowTitle: [
						{ required: true, message: '请输入标题', trigger: 'blur' },
					],
					padShowContent: [
						{ required: true, message: '请输入内容', trigger: 'blur' },
					],
					padShowImage:[
						{ required: true, message: '请选择图片', trigger: 'blur' },
					]
				},
				imageSrc: '', // 图片地址
				imageSrcList: [] ,// 图片放大数组
				action: baseURL + '/setting/pad_show?subact=upload_show_image',
				headers: {
					acctoken: window.sessionStorage.getItem('token')
				}
			}
		},
		created() {
			this.queryData();
		},
		
		mounted() {
			
		},
		methods:{
			// 获取数据
			async queryData(){
				let { data, errcode } = await api.padShow({},'get_show_info');
				this.formData.padShowTitle = data.PadShowTitle;
				this.formData.padShowContent = data.PadShowContent;
				this.formData.padShowImage = data.PadShowImage;
				this.imageSrc = data.PadShowImageUrl;
				this.imageSrcList.push(data.PadShowImageUrl)
			},
			// 上传文件限制事件
			beforeAvatarUpload(file){
				const isJPG = file.type === 'image/jpeg';
				const isGIF = file.type === 'image/gif';
				const isPNG = file.type === 'image/png';
				const isBMP = file.type === 'image/bmp';
				const isLt2M = file.size / 1024 / 1024 < 2;
				 if (!isJPG && !isGIF && !isPNG && !isBMP) {
					this.$message.error('上传图片必须是JPG/GIF/PNG/BMP 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
				}
				return (isJPG || isBMP || isGIF || isPNG) && isLt2M;
			},
			// 上传文件成功事件
			onSuccess(event){
				let { url, uri } = event.data;
				this.imageSrc = url;
				this.formData.padShowImage = uri;
			},
			// 上传文件失败事件
			onError(event){
				this.$message.error('上传图片失败！');
			},
		  onSubmit(formName){
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							let submitData = {
								PadShowTitle: this.formData.padShowTitle,
								PadShowContent: this.formData.padShowContent,
								PadShowImage: this.formData.padShowImage
							}
							let data = await api.pad_show(submitData,'save_show_info');
							this.$message({ message: '设置成功', type: 'success' });
						}catch(err) { 
							this.$message.error('设置失败');
						}
					} else {
						return false;
					}
				});
				
			}
		}
	}
</script>

<style lang="less" scoped>
.el-form{
	width: 720px;
	margin: 0 auto;
	.el-image{
		height: 220px;
		width: 220px;
		border-radius: 6px;
		border: 1px solid #EEEEEE;
	}
}
</style>
